import payload_plugin_dHWDzdkIDW from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_x83kxlONK3 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IRsINVN5F3 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zdKmW4Wv8H from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PvGjHahepC from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_22uurpu4izdfvf3idrouvh3f2u/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_YmQImlmdo6 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Bzoxp15J7N from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bb1nmsxBKc from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hPYwjj5FnB from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/delivery-doves/delivery-doves/.nuxt/components.plugin.mjs";
import prefetch_client_jOMEXIKmgv from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gTWzI14ypK from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_sass_22ped5kddqyg6zlhfcuyt44b6m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_vuxqzlJfuf from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import siteConfig_zPavVnKHVa from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_4f2btozeyz6jgzew6fe6rnauva/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_wqGtnHpqiu from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_4f2btozeyz6jgzew6fe6rnauva/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_CpPQdBmr4r from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_4f2btozeyz6jgzew6fe6rnauva/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_Hh7PYgwTMn from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-csurf@1.6.5_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import authenticated_fetch_client_kRaJ5FV55P from "/home/runner/work/delivery-doves/delivery-doves/plugins/authenticated-fetch.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/delivery-doves/delivery-doves/plugins/firebase.client.ts";
import init_driver_store_client_bQc6iNNq6D from "/home/runner/work/delivery-doves/delivery-doves/plugins/init-driver-store.client.ts";
import init_vendor_store_client_9hhcheX78g from "/home/runner/work/delivery-doves/delivery-doves/plugins/init-vendor-store.client.ts";
import vee_validate_client_P40JVl0wNI from "/home/runner/work/delivery-doves/delivery-doves/plugins/vee-validate.client.ts";
import defaults_VfFUkPxTZl from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_4f2btozeyz6jgzew6fe6rnauva/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_x83kxlONK3,
  unhead_IRsINVN5F3,
  router_zdKmW4Wv8H,
  _0_siteConfig_PvGjHahepC,
  payload_client_YmQImlmdo6,
  navigation_repaint_client_Bzoxp15J7N,
  check_outdated_build_client_bb1nmsxBKc,
  chunk_reload_client_hPYwjj5FnB,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jOMEXIKmgv,
  plugin_gTWzI14ypK,
  preview_client_vuxqzlJfuf,
  siteConfig_zPavVnKHVa,
  inferSeoMetaPlugin_wqGtnHpqiu,
  titles_CpPQdBmr4r,
  plugin_Hh7PYgwTMn,
  authenticated_fetch_client_kRaJ5FV55P,
  firebase_client_zXNDa0wxFH,
  init_driver_store_client_bQc6iNNq6D,
  init_vendor_store_client_9hhcheX78g,
  vee_validate_client_P40JVl0wNI,
  defaults_VfFUkPxTZl
]