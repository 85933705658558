import { country } from "@breezehr/currency-codes";

export default defineAppConfig({
  audience: "delivery-doves",
  adminEmail: "info@deliverydoves.com",
  PLATFORM_COMISSION_PERCENTAGE: 5,
  PLATFORM_DELIVERY_FEE: 5,
  PLATFORM_COMISSION_FIXED: 2,
  placeholderImages: {
    banner: "/images/tempbanner.webp",
    logo: "/images/templogo.webp",
  },
  recaptchaSiteKey: "6LfbRwAlAAAAAC32pryw5pFwL6b2IUxhpOVi5Od9",
  currency: country("Virgin Islands (British)")[0],
  currencySymbol: "$",
  firstAtlanticCommerce: {
    hostedPaymentPage: {
      id: 4004,
      set: "default",
      name: "default",
    },
  },
  ordersRefreshInterval: 1000 * 15, // 15 Seconds
});
