import validate from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/home/runner/work/delivery-doves/delivery-doves/middleware/admin.global.ts";
import manifest_45route_45rule from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.16.0_jiti@2.4.2__ior_3su4ascwte2fkitnmby3qq3v4m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/delivery-doves/delivery-doves/middleware/auth.ts"),
  "logged-out": () => import("/home/runner/work/delivery-doves/delivery-doves/middleware/logged-out.ts")
}