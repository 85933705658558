import { defineStore, skipHydrate } from "pinia";
import { collection, doc, query, where, type DocumentReference } from "firebase/firestore";
import { ref as storRef } from "firebase/storage";

export const useVendor = defineStore("vendor", () => {
  const { $firebaseFirestore, $firebaseStorage, $authFetch } = skipHydrate(useNuxtApp());
  const { uid } = skipHydrate(storeToRefs(useUser()));

  const vendorRef = computed(() => {
    if (uid.value && $firebaseFirestore)
      return doc($firebaseFirestore, "vendors", uid.value) as DocumentReference<Vendor>;
  });
  const storageRef = computed(() => {
    if (uid.value && $firebaseStorage) return storRef($firebaseStorage, `vendors/${uid.value}`);
  });

  const vendor = useFirestore(vendorRef);

  const orderCountQuery = computed(() => {
    if (uid.value && $firebaseFirestore)
      return query(collection($firebaseFirestore, "orders"), where("vendor.id", "==", uid.value));
  });
  const orderList = useFirestore(orderCountQuery);
  const orderCount = computed(() => orderList.value?.length ?? 0);
  watch(orderCount, (newCount, oldCount) => {
    if (newCount !== oldCount) refreshOrders();
  });

  // Get the vendor's orders
  const orders = ref<Order[]>([]);
  const { trigger: refreshOrders } = watchTriggerable(
    uid,
    (id) => {
      if (id) {
        $authFetch<Order[]>(`/api/vendor/${id}/orders`).then((newOrders) => {
          orders.value = newOrders;
        });
      } else orders.value = [];
    },
    { immediate: true }
  );

  return { vendor, vendorRef, storageRef, orders, refreshOrders, orderCount };
});
